import React from 'react';
import PropTypes from 'prop-types';
import { StoryblokCache, initializeRedis } from '@cache/redis';
import { OllieStoryblokClient } from '@web/cms/api';
import { AccountContainer } from '../../account';
import { PrefixedReporterProvider } from '../../reporter';
import { MetaAttributes } from '../../head';
import { withFlagshipSession } from '../../auth/serverSide';
import { withGladlySetting } from '../../gladly';
import { server } from '../../config';
import { schema as accountDashboardSchema } from '../../cms/builders/account_dashboard';

const AccountPage = ({ content }) => {
  const accountDashboard = accountDashboardSchema.validateSync(content);
  return (
    <PrefixedReporterProvider prefix={accountDashboard.taggingPrefix}>
      <MetaAttributes seoTitle={accountDashboard.seoTitle} />
      <AccountContainer {...accountDashboard} />
    </PrefixedReporterProvider>
  );
};

/**
 * @type {import('next').GetServerSideProps}
 */
export const getServerSideProps = withGladlySetting(
  withFlagshipSession(async context => {
    const redis = initializeRedis({
      host: server.REDIS_HOST,
      port: server.REDIS_PORT,
    });
    const storyblokRedis = new StoryblokCache(redis);
    const ollieStoryblokClient = new OllieStoryblokClient(storyblokRedis);

    const res = await ollieStoryblokClient.getStory(
      OllieStoryblokClient.ACCOUNT_SLUG,
    );
    const { content } = res.data.story;

    return {
      props: {
        content,
      },
    };
  }),
);

AccountPage.displayName = 'AccountPage';

AccountPage.propTypes = {
  content: PropTypes.object,
};

export default AccountPage;
